import HELPERS from '@/modules/OperatorPanel/helpers';
import Vue from 'vue';
import axios from '../../axios-middleware';
import i18n from '@/lang/i18n.js';
import kwizbotModuleSettings from '@/modules/OperatorPanel/kwizbot_settings.js';
import { v4 as uuid_v4 } from 'uuid';

const options = {
  // second: "2-digit",
  // minute: "2-digit",
  // hour: "2-digit",
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};
const INTL = new Intl.DateTimeFormat('ru-RU', options);

const state = {
  operator_panel: {
    chatRooms: [],
    notification: false,
    activeChatGroup: null,
    activeChatRoom: null,
    serverTime: null,
    subjectList: [],
    subjectListTree: [],
    rebuildKey: Date.now(),

    chatRoomGroups: [],
    typeChatRooms: {type: 'unassigned', id: ''},
    typeChatRoomsEnum: {
      all: 'all',
      unassigned: 'unassigned',
      my: 'my',
      operator: 'operator',
      subject: 'subject',
      closedChats: 'closedChats',
			queue: 'queue',
    },
    searchChatRoomsInput: '',

    groupByTheme: false,

    closedChatRoomsShow: false,

    closedRoomsSearchParams: {
      //users List
      operators: [],
      //subjects List
      subjects: [],
      //days toggle
      days: 7,
      //dates range
      from: new Date(new Date().setDate(new Date().getDate() - 6)).toISOString().slice(0, 10),
      to: new Date().toISOString().slice(0, 10),
      //clients text
      search: '',
      //groupBy
      group: '',
    },
    closedRoomsSearchParamGroupByEnum: [
      {name: i18n.t('modules.op.search.item_operator'), value: "operator"},
      {name: i18n.t('modules.op.search.item_subject'), value: "subject"},
      {name: i18n.t('modules.op.search.item_date'), value: "date"},
      {name: i18n.t('modules.op.search.item_client'), value: "client"},
    ],
    closedRoomsSearch: [],
		tagsList: [],
  },
};

const mutations = {
  OP_SET_CHAT_ROOMS(state, chatRooms) {
    Vue.set(state.operator_panel, 'chatRooms', chatRooms);
  },

  OP_SET_ACTIVE_CHAT_GROUP(state, chatGroupId) {
    Vue.set(state.operator_panel, 'activeChatGroup', chatGroupId);
  },

  OP_SET_ACTIVE_CHAT_ROOM(state, chatRoomId) {
    Vue.set(state.operator_panel, 'activeChatRoom', chatRoomId);
  },

  OP_SET_CHAT_ROOM_PARTICIPANTS(state, chatRoom) {
    const participants = HELPERS.formatDialogParticipants(chatRoom);
    Vue.set(state.operator_panel.active_chat, 'participants', participants);
  },

  OP_SET_SERVER_TIME(state, dateStr) {
    state.operator_panel.serverTime = dateStr;
  },

  OP_SET_SUBJECT_LIST(state, subjectList) {
    state.operator_panel.subjectList = subjectList;
  },

  OP_SET_SUBJECT_LIST_TREE(state, subjectList) {
    state.operator_panel.subjectListTree = subjectList;
  },

  OP_SET_ACTIVE_CHAT_ROOM_URL(state, chatRoomId) {
    const menuItem = kwizbotModuleSettings.module_settings.menu_items[0];

    let url = `/${menuItem.route}/chats`;

    if (chatRoomId) {
      url += `?chat_room_id=${chatRoomId}`;
    }

    // enable/disable add chats?chat_id to url
    window.history.pushState(menuItem.title, 'title', url);
  },

  OP_SET_REBUILD_KEY(state, rebuildKey) {
    state.operator_panel.rebuildKey = rebuildKey;
  },

  OP_SET_CHAT_NOTINICATION(state, message) {
    // console.log(message, 'OP_SET_CHAT_NOTINICATION')
    const { chatRooms } = state.operator_panel;
    const room = chatRooms.find((v) => v.id === message?.data?.chat_room_id);

    state.operator_panel.notification = true;

    if (room) {
      const activeMessage = room.messages.find((v) => v.id === message.data.id);

      if (activeMessage) {
        activeMessage.message_read = message.data.message_read;
      } else {

        //add to message param "send_datetime" from "updatedAt"
        if ( !message.data?.send_datetime )
          message.data['send_datetime'] = message.data.updatedAt.replace('T', ' ').split('.')[0]

        room.messages.push(message.data);
      }
    }
  },
  OP_ADD_MESSAGE_TO_ACTIVE_CHATROOMS (state, message) {
    const { chatRooms } = state.operator_panel;
    const room = chatRooms.find((v) => v.id === message?.data?.chat_room_id);

    state.operator_panel.notification = false;

    if (room) {
      //add to message param "send_datetime" from "updatedAt"
      if ( !message.data?.send_datetime )
        message.data['send_datetime'] = message.data.updatedAt.replace('T', ' ').split('.')[0]

      message.data.message_read = true

      room.messages.push(message.data);

			//push to active dialog One msg
			const userId = state.operator_panel.userInfo.id;
			const options = { messages: [message.data], userId };
			// Find the index where the object should be inserted
			const index = state.operator_panel.active_chat.messageList
				.findIndex(item => item.id > message.data.id);
			// If no larger ID is found, index will be -1, and we should push at the end of the array
			if (index === -1) {
				state.operator_panel.active_chat.messageList.push(HELPERS.formatDialogMsgs(options)[0]);
			} else {
				state.operator_panel.active_chat.messageList.splice(index, 0, HELPERS.formatDialogMsgs(options));
			}
			// state.operator_panel.active_chat.messageList = [...state.operator_panel.active_chat.messageList, ...HELPERS.formatDialogMsgs(options)];
    }
  },

  OP_SET_CHAT_READ(state, chatId) {
    const { chatRooms } = state.operator_panel;
    const room = chatRooms.find((v) => v.id === chatId);

    state.operator_panel.notification = false;

    if (room) {
      room.messages.forEach((r) => (r.message_read = true));
    }
  },

  OP_SET_TYPE_CHAT_ROOMS(state, typeChatRoomsVal) {
    state.operator_panel.typeChatRooms = typeChatRoomsVal
  },

  OP_SET_GROUP_BY_THEME(state, groupByTheme) {
    state.operator_panel.groupByTheme = groupByTheme
  },

  OP_SET_CHAT_ROOM_GROUPS(state, chatRoomGroups) {
    state.operator_panel.chatRoomGroups = chatRoomGroups
  },

  OP_SET_CLOSED_CHAT_ROOMS_SHOW(state, value) {
    state.operator_panel.closedChatRoomsShow = value
  },

  OP_SET_SEARCH_CHAT_ROOMS_INPUT(state, value) {
    state.operator_panel.searchChatRoomsInput = value
  },

  OP_SET_CLOSED_ROOMS_SEARCH(state, value) {
    state.operator_panel.closedRoomsSearch = value
  },

  OP_SET_CLOSED_ROOMS_SEARCH_PARAMS(state, value) {
    Vue.set(
      state.operator_panel.closedRoomsSearchParams,
      Object.keys(value)[0],
      value[Object.keys(value)[0]]
    )
  },

	OP_SET_TAGS_LIST(state, payload) {
		state.operator_panel.tagsList = payload
	},
};

const actions = {
  opSetChatNotification({ commit }, data) {
    commit('OP_SET_CHAT_NOTINICATION', data);
  },
  opAddMessageToActiveChatRooms({ commit }, data) {
    commit('OP_ADD_MESSAGE_TO_ACTIVE_CHATROOMS', data);
  },

  opSetChatReed({ commit }, data) {
    commit('OP_SET_CHAT_READ', data);
  },

  async OpAxiosGetChatRoomsRoot({ commit }, query) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/list/`;

    const params = query ? { params: query } : null;

    const response = await axios.get(url, params).catch((err) => {
      HELPERS.handleError([
        err,
        i18n.t('common.error'),
        i18n.t('common.error_get'),
      ]);
      return null;
    });

    return response;
  },

  async OpAxiosGetChatRooms({ commit, dispatch }) {
    const response = await dispatch('OpAxiosGetChatRoomsRoot');

    if (response) {
      const serverTime = response.data?.data?.current_datetime || null;
      const chatRooms = response.data?.data?.chat_rooms || [];

      commit('OP_SET_SERVER_TIME', serverTime);
      commit('OP_SET_CHAT_ROOMS', chatRooms);

      dispatch('opSetChatRoomGroups', chatRooms);
    } else {
      commit('OP_SET_CHAT_ROOMS', []);
      commit('OP_SET_CHAT_ROOM_GROUPS', [])
    }
  },

  /**
   * Create new chat room
   * @method OpAxiosCreateChatRoom
   * @param chatRoomTitle
   *
   */
  async OpAxiosCreateChatRoom(
    { state, commit, dispatch },
    { chatRoomTitle, number, channel, clientId, type, botId, fromOperator }
  ) {
    if (number) number = number.replace(/[()\s\-.]/g, '');
    else number = '';

    let chat_room_request = {
      kw_channel: channel || 'kwizbot',
      kw_chat_id: number || uuid_v4(),
      subject_id: null,
      bot_id: botId || state?.bot_settings?.active_id,
      kw_chat_title: chatRoomTitle || null,
      type: type || null,
      from_operator: fromOperator || false,
    };

    console.log('chat_room_request', chat_room_request);

    /** СОЗДАЕМ НОВУЮ КОМНАТУ **/

    let url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/create/`;

    const chat_room_id = await axios
      .post(url, chat_room_request)
      .then((response) => {
        if (
          response.data &&
          response.data.data &&
          response.data.data.chat_room_id
        ) {
          return response.data.data.chat_room_id;
        } else return 0;
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_modify'),
        ]);
        return false;
      });

    if (!chat_room_id) {
      return 0;
    }

    //console.log("chat room id:" + chat_room_id)

    /**создаем OP client хотя он по факту не нужен **/

    /* const client_create_request = {
        "alias": chat_room_request.kw_channel + '_' + chat_room_request.kw_chat_id,
        "kw_channel": chat_room_request.kw_channel,
        "kw_chat_id": chat_room_request.kw_chat_id,
        //"full_name":chatRoomTitle,
        // "extra_data": {is_group: true}

    }

    url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/client/get_or_create/`;

    const client_id = await axios
      .post(url, chat_room_request)
      .then((response) => {

        if(response.data && response.data.data && response.data.data.client && response.data.data.client.id){
            return response.data.data.client.id

        } else return 0;

      })
      .catch((err) => {
        HELPERS.handleError([err, i18n.t('common.error'), i18n.t('common.error_modify')]);
        return false;
      });

    if(!client_id){
        return 0
    }*/

    const currentUserId = state.operator_panel.userInfo.id;

    /**ПОДКЛЮЧАЕМ ОПЕРАТОРА и псевдоклиента В ЧАТ СРАЗУ**/

    //console.log(currentUserId)
    let participants_add_request = {
      chat_room_id,
      participants: [
        {
          type: 'user',
          user_id: currentUserId,
        },
        /* {
                "type": "client",
                "user_id":  client_id
            }*/
      ],
    };

    if (clientId) {
      participants_add_request.participants.push({
        type: 'client',
        client_id: clientId,
      });
    }

    url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/participants/add/`;

    await axios.post(url, participants_add_request).catch((err) => {
      HELPERS.handleError([
        err,
        i18n.t('common.error'),
        i18n.t('common.error_modify'),
      ]);
      return false;
    });

    return chat_room_id;
  },

  async opGetSudjectList({ commit }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/subjects/list/`;

    const subjects = await axios
      .get(url)
      .then((response) => {
        let subjectsList = response.data?.data?.subjects || []

        if (subjectsList.length) {
          subjectsList = subjectsList.filter( ({is_deleted}) => !is_deleted)
        }
        return subjectsList;
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_get'),
        ]);
        return [];
      });

    commit('OP_SET_SUBJECT_LIST', subjects);
  },

  async opGetSudjectListTree({ commit }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/subjects/listTree/`;

    const subjects = await axios
      .get(url)
      .then((response) => {
        return response.data?.data?.subjects || [];
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_get'),
        ]);
        return [];
      });

    commit('OP_SET_SUBJECT_LIST_TREE', subjects);
  },

  async opAxiosChangeChatRoomTheme({ dispatch }, query) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/chat_room/subject/change/`;

    const { roomId, subjectId } = query;

    const request = {
      chat_room_id: roomId,
      subject_id: subjectId,
    };

    const success = await axios
      .post(url, request)
      .then((response) => {
        if (response.data?.status === 'success') dispatch('OpAxiosGetChatRooms')
        return response.data?.status === 'success';
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_modify'),
        ]);
        return false;
      });

    return success;
  },

  opSetActiveChatGroup({ commit }, chatGroupId) {
    commit('OP_SET_ACTIVE_CHAT_GROUP', chatGroupId);
  },

  opSetActiveChatRoom({ commit, getters }, chatRoomId) {
    commit('OP_SET_ACTIVE_CHAT_ROOM', chatRoomId);

    const activeChatRoom = getters.opActiveChatRoom;

    commit('OP_SET_CHAT_ROOM_PARTICIPANTS', activeChatRoom);

    commit(
      'OP_SET_ACTIVE_CHAT_ROOM_URL',
      chatRoomId || activeChatRoom?.id || null
    );

    commit('OP_SET_CHAT_READ', chatRoomId || activeChatRoom?.id || null);
  },
	opSetActiveChatRoomById({getters, dispatch}, chatRoomId) {
		// dispatch('OpAxiosGetChatRoomClientId', chatRoomId)
		dispatch('opSetActiveChatRoom', chatRoomId)

		const participants = getters.opActiveChatRoom?.participants || [];
		const isClient = (participant) => participant.type === 'client';
		const client = participants.find(isClient) || {};

		dispatch('opGetClientInfo', client.client_id)
		dispatch('opGetClientHistory', client.client_id)

		dispatch('opSetTopSidebarOpen', true)
		dispatch('opSetRightSidebarOpen', true)

		setTimeout(() => {
			dispatch('OpAxiosGetChatRoomMessages', chatRoomId);
		}, 250);
	},

  opSetActiveClosedChatRoom({ commit, getters }, chatRoomId) {
    commit('OP_SET_ACTIVE_CHAT_ROOM', chatRoomId);

    // const activeChatRoom = getters.opActiveChatRoom;

    // commit('OP_SET_CHAT_ROOM_PARTICIPANTS', activeChatRoom);

    // commit('OP_SET_CHAT_READ', chatRoomId || activeChatRoom?.id || null);
  },

  opClearActiveChatRoomOnDestroy({ commit, getter }, chatRoomId) {
    commit('OP_SET_ACTIVE_CHAT_ROOM', chatRoomId);

    const activeChatRoom = getters.opActiveChatRoom;

    commit('OP_SET_CHAT_ROOM_PARTICIPANTS', activeChatRoom);

    commit('OP_SET_CHAT_READ', chatRoomId || activeChatRoom?.id || null);
  },

  opSetTypeChatRooms ( {commit, getters}, typeChatRoomsVal ) {
    //check if closedChat active
    (typeChatRoomsVal.type === getters.opTypeChatRoomsEnum.closedChats )
      ? commit('OP_SET_CLOSED_CHAT_ROOMS_SHOW', true)
      : commit('OP_SET_CLOSED_CHAT_ROOMS_SHOW', false)

    commit('OP_SET_TYPE_CHAT_ROOMS', typeChatRoomsVal)
  },

  opSetGroupByTheme ( {commit}, groupByTheme ) {
    commit('OP_SET_GROUP_BY_THEME', groupByTheme)
  },

  opSetChatRoomGroups ( {state, commit}, chatRooms = null ) {
    if (!chatRooms) chatRooms = state.operator_panel.chatRooms;

    const { subjectList } = state.operator_panel;

    const subjectObj = {
      alias: null,
      id: 0,
      name: i18n.t('modules.op.texts.without_subject'),
      name_en: 'Without theme',
      name_ru: i18n.t('modules.op.texts.without_subject'),
      name_uk: i18n.t('modules.op.texts.without_subject'),
      parent_id: null,
      dialogs: [],
      childSubjectIds: [0],
    };

    //sort ChatRooms
    const chatRoomsWithoutSubject = [];
    const chatRoomsWithSubject = [];

    const sortChatRooms = (chatRoom) => {
      chatRoom.subject_id === null
        ? chatRoomsWithoutSubject.push(chatRoom)
        : chatRoomsWithSubject.push(chatRoom);
    };

    chatRooms.forEach(sortChatRooms);

    if (chatRoomsWithoutSubject.length) {
      chatRoomsWithoutSubject.forEach(function (chatRoom) {
        subjectObj.dialogs.push(chatRoom)
      })
    }

    let subjectListWithDialogs = []

    if (chatRoomsWithSubject.length) {
      for (let subject of subjectList) {
        subject['dialogs'] = chatRoomsWithSubject.filter( ({subject_id}) => subject.id === subject_id  )
      }
      subjectListWithDialogs = subjectList.filter( ({dialogs}) => dialogs.length > 0 )
    }

    const chatRoomGroups = [subjectObj, ...subjectListWithDialogs]

    commit('OP_SET_CHAT_ROOM_GROUPS', chatRoomGroups)
  },

  opAxiosClosedRoomsByClients ({commit}, query) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/closed_rooms_by_clients/`;

    const params = query ? { params: query } : null;

    return axios
      .get(url, params)
      .then((response) => {
        return response.data?.data?.chat_rooms || [];
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_get'),
        ]);
        return [];
      });
  },

  setClosedChatRoomsShow ({ commit }, value) {
    commit('OP_SET_CLOSED_CHAT_ROOMS_SHOW', value)
  },

  setSearchChatRoomsInput ( {commit}, value ) {
    commit('OP_SET_SEARCH_CHAT_ROOMS_INPUT', value)
  },

  setAxiosClosedRoomsSearch ({ commit, dispatch }, query) {
    dispatch("updateAjaxDialog", [true, true]);
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/closed_rooms_search/`;

    //increase date_to to One day, for request on back
    if (query?.to) {
      let dayTo = new Date(query.to)
      query.to = new Date(dayTo.setDate(dayTo.getDate() + 1)).toISOString().slice(0, 10)
    }

    const params = query ? { params: query } : null;

    return axios
      .get(url, params)
      .then((response) => {
        // commit('OP_SET_CLOSED_ROOMS_SEARCH', response.data?.data?.chat_rooms || [])
        dispatch("updateAjaxDialog", [false, false]);
        return response.data?.data?.chat_rooms || [];
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_get'),
        ]);
        return [];
      });
  },

  setClosedRoomsSearchParams({ commit }, option) {
    commit('OP_SET_CLOSED_ROOMS_SEARCH_PARAMS', option)
  },

	opAxiosTagsList({ commit, getters }, data) {
		let {chatTags, request} = {...data}
		const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/tags/list/${chatTags && getters.opActiveChatRoomFromState ? getters.opActiveChatRoomFromState : ''}`;
		if (!request) request = {
			search: '',
			limit: 100,
			offset: 0,
		};

		return axios
			.get(url, request)
			.then((response) => {
				commit('OP_SET_TAGS_LIST', response?.data?.data?.tags || [])
				return response?.data?.data?.tags || []
			})
			.catch((err) => {
				HELPERS.handleError([
					err,
					i18n.t('common.error'),
					i18n.t('common.error_get'),
				]);
				return [];
			});
	},
};

const getters = {
  opIsNotification: (state) => {
    const { notification } = state.operator_panel;

    return notification;
  },

  opChatRooms: (state) => {
    const { chatRooms } = state.operator_panel;

    const addDate = function addFormatDate(room) {
      const date = new Date(room.createdAt);
      const formatDate = INTL.format(date);

      room.date = formatDate;
    };

    chatRooms.forEach(addDate);

    return chatRooms;
  },

  opChatRoomGroups2: (state) => {
    const { chatRooms, subjectList } = state.operator_panel;

    let chatRoomGroups = [];

    const subjectObj = {
        alias: null,
        id: 0,
        name: i18n.t('modules.op.texts.without_subject'),
        name_en: 'Without theme',
        name_ru: i18n.t('modules.op.texts.without_subject'),
        name_uk: i18n.t('modules.op.texts.without_subject'),
        parent_id: null,
        dialogs: [],
        childSubjectIds: [0],
    };

    //sort ChatRooms
    const chatRoomsWithoutSubject = [];
    const chatRoomsWithSubject = [];

    const sortChatRooms = (chatRoom) => {
      chatRoom.subject_id === null
        ? chatRoomsWithoutSubject.push(chatRoom)
        : chatRoomsWithSubject.push(chatRoom);
    };

    chatRooms.forEach(sortChatRooms);

    if (chatRoomsWithoutSubject.length) {
      chatRoomsWithoutSubject.forEach(function (chatRoom) {
        subjectObj.dialogs.push(chatRoom)
      })
      chatRoomGroups.push(subjectObj)
    }

    if (chatRoomsWithSubject.length) {

      chatRoomsWithSubject.forEach(function (chatRoom) {
        for (let i in subjectList) {
          if (subjectList[i].id === chatRoom.subject_id) {
            if (subjectList[i]?.dialogs === undefined) subjectList[i]['dialogs'] = []
            subjectList[i]?.dialogs.push(chatRoom)
            chatRoomGroups.push( subjectList[i] )
          }
        }
      })
    }

    return chatRoomGroups
  },

  opChatRoomsWithGroup: (state) => {
    const { chatRooms, subjectList } = state.operator_panel;
    const chatRoomsWithSubjects = chatRooms.filter( ({subject_id}) => subject_id != null )

    for (let subject of subjectList) {
      subject['dialogs'] = chatRoomsWithSubjects.filter( ({subject_id}) => subject.id === subject_id )
    }

    return subjectList.filter( (subject) => subject.dialogs.length > 0)
  },

  opChatRoomsWithoutGroup (state) {
    const { chatRooms } = state.operator_panel;
    return  chatRooms.filter( ({subject_id}) => subject_id === null )
  },

  opActiveChatGroup: (state, getters) => {
    const activeChatGroupId = state.operator_panel.activeChatGroup;

    const isEqualId = (group) => group.id === activeChatGroupId;

    // const activeGroup = getters.opChatRoomGroups.find(isEqualId);
    const activeGroup = state.operator_panel.chatRoomGroups.find(isEqualId);

    return activeGroup || null;
  },

  opActiveChatRoom: (state) => {
    const { chatRooms } = state.operator_panel;

    const activeChatRoomId = state.operator_panel.activeChatRoom;

    if (activeChatRoomId === null) {
      return null;
    }

    const isEqualId = (room) => room.id === activeChatRoomId;

    const activeChatRoom = chatRooms.find(isEqualId);

    return activeChatRoom || null;
  },

  opActiveChatRoomFromState: (state) => state.operator_panel.activeChatRoom,

  opServerTime: (state) => state.operator_panel.serverTime,

  opSubjectList: (state) => state.operator_panel.subjectList,

  opSubjectListTree: (state) => state.operator_panel.subjectListTree,

  opRebuildKey: (state) => state.operator_panel.rebuildKey,

  opTypeChatRooms: (state) => state.operator_panel.typeChatRooms,
  opTypeChatRoomsEnum: (state) => state.operator_panel.typeChatRoomsEnum,

  opGroupByTheme: (state) => state.operator_panel.groupByTheme,

  opChatRoomGroupsNew: (state) => state.operator_panel.chatRoomGroups,

  opClosedChatRoomsShow: (state) => state.operator_panel.closedChatRoomsShow,

  opSearchChatRoomsInput: (state) => state.operator_panel.searchChatRoomsInput,

  /**
   * @name opFilteredChatRooms
   * @description main function for filter chat rooms by search and type
   * @param state
   * @param getters
   * @returns filtered array of chatRooms
   */
  opFilteredChatRooms: (state, getters) => {
    const searchVal = (getters.opSearchChatRoomsInput === null)
      ? ''
      : getters.opSearchChatRoomsInput.toLowerCase();
    const dialogs = getters.opChatRooms || [];

    const isClient = (participant) => participant.type === 'client';
    const isUser = (participant) => participant.type === 'user';

    const concatInfo = (acc, infoValue) => acc + infoValue;

    const isEqualSearch = (client) => {
      const clientInfoObj = client.client || {};
      const clientInfo = Object.values(clientInfoObj).reduce(
        concatInfo,
        ''
      );
      const normClientInfo = clientInfo.toLowerCase();

      return normClientInfo.includes(searchVal);
    };

    const compareClientFullName = (dialog) => {
      const clients = dialog.participants.filter(isClient);
      return clients.some(isEqualSearch);
    };

    const userId = getters.opUserInfo.id;

    const isEqualUserId = (user) => user.user_id === userId;

    //filter for unassigned dialogs
    const compareDialogUnassigned = (dialog) => {
      const users = dialog.participants.filter( ({type}) => type === 'user' );
      return users.length <= 0;
    };

    //filter assigned to current user
    const compareDialogUser = (dialog) => {
      const users = dialog.participants.filter(isUser);
      return users.some(isEqualUserId);
    };

		//filter assigned to current user
		const compareDialogQueue = (dialog) => {
			return dialog.status === 'queue';
		};

    //filter assigned to user by ID
    const compareDialogUserById = (dialog, userId) => {
      const users = dialog.participants.filter(isUser);
      return users.some((user) => user.user_id === userId);
    };

    const compareDialogSubjectById = (dialog, subjectId) => {
      return (subjectId === 0)
        ? dialog.subject_id === null
        : dialog.subject_id === subjectId
    };

    const ifTested = (dialog) => {
      const ifPassFullName =
        searchVal === '' || compareClientFullName(dialog);

      //filter from ChatsMenu.vue
      let isPassTypeCheck
      switch (getters.opTypeChatRooms?.type) {
        case getters.opTypeChatRoomsEnum.all:
          isPassTypeCheck = true
          break;
        case getters.opTypeChatRoomsEnum.unassigned:
          isPassTypeCheck = compareDialogUnassigned(dialog)
          break;
        case getters.opTypeChatRoomsEnum.my:
          isPassTypeCheck = compareDialogUser(dialog)
          break;
				case getters.opTypeChatRoomsEnum.queue:
					isPassTypeCheck = compareDialogQueue(dialog)
					break;
        case getters.opTypeChatRoomsEnum.operator:
          isPassTypeCheck = compareDialogUserById(dialog, getters.opTypeChatRooms.id)
          break;
        case getters.opTypeChatRoomsEnum.subject:
          isPassTypeCheck = compareDialogSubjectById(dialog, getters.opTypeChatRooms.id)
          break;
        default:
          isPassTypeCheck = false
      }

      return ifPassFullName && isPassTypeCheck;
    };

    const filteredDialogs = dialogs.filter(ifTested);

    const byDateDesc = (a, b) => {
      const aLastMsg = a.messages[a.messages.length - 1];
      const aLastMsgTime = aLastMsg?.send_datetime;
      const aDialogTime = a.start_datetime;
      const aStartTime = aLastMsgTime || aDialogTime;

      const bLastMsg = b.messages[b.messages.length - 1];
      const bLastMsgTime = bLastMsg?.send_datetime;
      const bDialogTime = b.start_datetime;
      const bStartTime = bLastMsgTime || bDialogTime;

      const aDate = new Date(aStartTime);
      const bDate = new Date(bStartTime);

      const aTime = aDate.getTime();
      const bTime = bDate.getTime();

      return aTime - bTime;
    };

    return filteredDialogs.sort(byDateDesc).reverse();
  },

  opClosedRoomsSearch: (state) => state.operator_panel.closedRoomsSearch,
  opClosedRoomsSearchParams: (state) => state.operator_panel.closedRoomsSearchParams,
  opClosedRoomsSearchParamGroupByEnum: (state) => state.operator_panel.closedRoomsSearchParamGroupByEnum,
  opTagsList: (state) => state.operator_panel.tagsList,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
